import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import {
  faUsers,
  faBriefcase,
  faCogs,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons'

import banner1 from '../../../images/pages/bpm/escritorioProcessos1.jpeg'
import banner2 from '../../../images/pages/bpm/escritorioProcessos2.jpeg'

const slides = [
  {
    title: (
      <>PERPETUE A GESTÃO POR PROCESSOS ATRAVÉS DO ESCRITÓRIO DE PROCESSOS</>
    ),
    description: (
      <>
        Consultoria para implantação do escritório de processos para garantir
        que a metodologia BPM agregue valor à organização
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: (
      <>
        BPM OFFICE COMO DIFERENCIAL COMPETITIVO PROMOVE INTEGRAÇÃO ENTRE ÁREAS
        DE NEGÓCIO
      </>
    ),
    description: (
      <>
        Mais do que um departamento, o escritório de processos possui atuação
        desde os processos operacionais aos estratégicos, é peça fundamental
        para estruturação e organização de rotinas.
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `P4 Engenharia`,
    text: `Tarefas repetitivas passam a ser executadas por Robos`,
    quantity: 74,
  },
  {
    title: `SASCAR`,
    text: `Aderências ao sistema Totvs`,
    quantity: 32,
  },
  {
    title: `UNIMED Rio Preto`,
    text: `Projeto de transformção digital e redução de atividades manuais`,
    quantity: 96,
  },
  {
    title: `Okubo`,
    text: `Estruturação da área comercial e aumento da aderência aos sistemas da empresa`,
    quantity: 40,
  },
]

const EscritorioDeProcessos: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faBriefcase,
      title: `Escritório de processos`,
      description: (
        <>
          O escritório de processos ou BPM Office é o embaixador da Gestão por
          Processos de Negócio (BPM) dentro das empresas. É responsável por
          apoiar toda a organização com metodologias, técnicas, ferramentas e
          treinamentos em processos desde o nível operacional ao estratégico,
          dando continuidade às iniciativas BPM, promovendo um ciclo de
          padronização e melhoria contínua.
        </>
      ),
    },
    {
      faIcon: faBullhorn,
      title: `Papéis do escritório`,
      description: (
        <>
          O escritório de processos deve difundir a cultura do BPM na
          organização. Deve capacitar os colaboradores, prover ferramentas e
          pessoas para mapeamento e melhoria de processos, atender à aberturas
          de chamados das áreas de negócios, gerenciar o desempenho dos
          processos alinhar os processos às metas organizacionais, dentre
          outros.
        </>
      ),
    },
    {
      faIcon: faCogs,
      title: `Características do escritório`,
      description: (
        <>
          As principais características do BPM Office envolvem
          multidisciplinaridade, independência, visão estratégica para conectar
          os processos às metas organizacionais, promover a integração entre
          àreas de negócio, inovação e possuir um processo próprio, afinal, deve
          considerar o mapeamento e melhoria de seus processos buscando otimizar
          suas funções.
        </>
      ),
    },
    {
      faIcon: faUsers,
      title: `Profissionais envolvidos`,
      description: (
        <>
          De acordo com o CBOK, o escritório de processos pode ficar posicionado
          na estrutura organizacional logo abaixo da liderança executiva e conta
          com profissionais como o analista de processos, o designer de
          processos, o arquiteto de processos e o gerente de processos,
          dependendo da estrutura da organização.
        </>
      ),
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Planejamento',
      description: (
        <>
          Na primeira etapa são planejados e preparados os recursos que serão
          parte do escritório de processos. É importante ter uma visão de longo
          prazo, já que inicialmente não é preciso formar uma equipe madura e
          com alto custo de investimento. É boa prática formar um grupo pequeno,
          com profissionais de áreas diferentes da organização, definindo
          atribuições e atividades para o grupo desenvolver dentro de suas
          rotinas.
        </>
      ),
    },
    {
      count: 2,
      title: 'Conscientização',
      description: (
        <>
          Após constituição do escritório de processos é necessário realizar um
          trabalho de conscientização para toda a organização, disseminando a
          mudança da visão departamental (hierárquica) para a visão processual
          (mais horizontalizada) de ponta a ponta. A alta liderança deve apoiar
          este processo comunicando toda a organização, apoiando as ações do
          escritório, fornecendo autonomia adequada e participando das tomadas
          de decisões.
        </>
      ),
    },
    {
      count: 3,
      title: 'Políticas e procedimentos',
      description: (
        <>
          Nesta etapa é criado um processo para fazer a gestão por processos,
          estabelecendo como o escritório irá funcionar. É boa prática criar um
          passo a passo orientando as principais dúvidas, como: o que fazer para
          sugerir melhorias, como se comunicar com o escritório, como abrir um
          chamado para mudança de um processo, procedimento ou documento, como
          monitorar o desempenho do processo, entre outros.
        </>
      ),
    },
    {
      count: 4,
      title: 'Capacitação',
      description: (
        <>
          Com os processos modelados, o escritório de processos deve capacitar
          os atores dos processos (colaboradores) e transferir os conhecimentos
          necessários para perpetuar a gestão por processos na organização. Além
          disso, deve monitorar a transformação dos processos, realizando
          diagnósticos, propondo melhorias, acompanhando os planos de ação e
          mostrando os resultados, contribuindo para a melhoria contínua.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="BPM - Escritório de Processos - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="Propor soluções através da implantação de escritório de processos - BPM
              Office - para perpetuar e aprimorar a gestão por processos na organização."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
            descripitionSection={`Conheça agora os 04 principais passos de nossa metodologia para
            implantar um escritório de processos em sua organização.`}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default EscritorioDeProcessos
